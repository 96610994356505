/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import 'assets/fonts/style.css';

ion-menu-button.md {
  --border-radius: 10px;
  margin: 10px;
  --background: var(--ion-color-primary);
  --color: white;
}

.header-md::after {
  background-image: none;
}

ion-backdrop {
  background: white !important;
}

ion-icon {
  &[class*='custom-'] {
    mask-size: contain;
    mask-position: 50% 50%;
    mask-repeat: no-repeat;
    background: currentColor;
    width: 1em;
    height: 1em;
  }

  &[class*='custom-mobile'] {
    mask-image: url(./assets/imgs/balls.svg);
  }
}
.swal2-icon.swal2-success {
  border-left-color: var(--ion-color-success) !important;
  border-right-color: var(--ion-color-success) !important;
}
.swal2-icon.swal2-success [class^='swal2-success-line'] {
  background-color: var(--ion-color-success) !important;
}

.swal2-styled.swal2-confirm {
  background-color: var(--ion-color-primary) !important;
}
.swal2-icon.swal2-error {
  border-left-color: var(--ion-color-danger) !important;
  border-right-color: var(--ion-color-danger) !important;
}

.swal2-icon.swal2-error [class^='swal2-x-mark-line'] {
  background-color: var(--ion-color-danger) !important;
}

.alert-checkbox-group::-webkit-scrollbar {
  width: 0.5em;
  display: block !important;
}

.alert-checkbox-group::-webkit-scrollbar-track {
  box-shadow: inset;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.alert-checkbox-group::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.ngx-pagination .current {
  background: var(--ion-color-primary) !important;
  border-color: #585b8f88;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
}

.alert-radio-group::-webkit-scrollbar,
.alert-checkbox-group::-webkit-scrollbar {
  width: 0.5em;
  display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track,
.alert-checkbox-group::-webkit-scrollbar-track {
  box-shadow: inset;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3) !important;
}

.alert-radio-group::-webkit-scrollbar-thumb,
.alert-checkbox-group::-webkit-scrollbar-thumb {
  background-color: #585b8f99 !important;
  outline: 2px solid #585b8f33 !important;
}
